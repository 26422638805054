<template>
    <div>
        <div class="m-2">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                            placeholder="Search..." />
                        <Addplugin @fetchPlugin="fetchPlugin" />
                        <!-- <Addplugin :manage="isManageThemes" :token="bearerToken" @fetchThemes="fetchThemes" /> -->
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-overlay :show="show" rounded="sm">
            <template v-if="loading">
                <div class="p-4 text-center"> 
                <b-spinner v-if="loading" label="Loading..." medium></b-spinner> 
            </div>
            </template>
            <template v-else-if="FilteredItems.length > 0">
                <b-table :items="FilteredItems" responsive :fields="fields" class="mb-0" id="theme-table">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(path)="data">
                        {{ data.item.path == null ? 'N/A' : data.item.path }}
                    </template>
                    <template #cell(action)="data">
                        <div class="d-flex align-items-center">
                            <b-dropdown variant="link" no-caret>
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item @click="editPluginData(data.item)">
                                    <div>
                                        <feather-icon icon="EditIcon" />
                                        <span class="align-middle ml-50">Edit</span>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deletePlugin(data.item._id, data.item.title)">
                                    <div>
                                        <b-spinner v-if="loading" small class="mr-1" />
                                        <feather-icon v-else icon="TrashIcon" />
                                        <span class="align-middle ml-50">Delete</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
            </template>
            <template v-else>
                <h4 class="text-center mb-2 mt-4" :style="{ fontSize: '16px' }">No records found</h4>
            </template>
        </b-overlay>

        <div>
            <EditpluginData :theme="editTheme" @fetchPlugin="fetchPlugin"/>
        </div>
        <!-- <EditTheme :token="token" :theme="editTheme" @fetchThemes="fetchThemes" @removeThemeData="removeThemeData" /> -->
    </div>
</template>

<!-- <template>
    <h2>
        ldsdkdnsdkndksndknkdsndnn
    </h2>
</template> -->
<script>
import {
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BSpinner,
    BCol,
    BCard,
    BRow,
} from "bootstrap-vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select"; 
export default {
    components: {
        Addplugin: () => import("./AddPlugin.vue"),
        EditpluginData: () => import("./EditPlugin.vue"),
        BButton,
        BFormInput,
        BFormGroup,
        BTable,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        vSelect,
        ToastificationContent,

    },
    data() {
        return {
            fields: [
                "index",
                { key: "title", label: "Title" },
                { key: "plugin", label: "Code" },
                { key: "platform", label: "PLATFORM" },
                { key: "path", label: "PATH" },
                { key: "action", label: "ACTION" },
            ],
            searchQuery: "",
            themes1:
                [
                    {
                        "name": { "value": "MissingElements", "text": "Missing Elements" },
                        "platform": "App",
                        "path": "/themes/theme-forest"
                    },
                    {
                        "name": { "value": "DragAndDrop", "text": "Drag And Drop" },
                        "platform": "App",
                        "path": "/themes/avada"
                    },
                    {
                        "name": { "value": "EvaluateNumbers", "text": "Evaluate Numbers" },
                        "platform": "App",
                        "path": "/themes/divi"
                    },
                    {
                        "name": { "value": "NumberChart", "text": "Number Chart" },
                        "platform": "Web",
                        "path": "/themes/enfold"
                    },
                    {
                        "name": { "value": "MissingNumbersEvaluate", "text": "Missing Numbers Evaluate" },
                        "platform": "App",
                        "path": "/themes/xstore"
                    }
                ],

            loading: false,
            editTheme: {},
            alertMessageToast: "",
            plugins: [],
        };
    },
    created() {
        this.fetchPlugins()
    },
    computed:{
        FilteredItems() { 
        if (!this.searchQuery) {
            return this.plugins;  
        }
        const query = this.searchQuery.toLowerCase();
        return this.plugins.filter(plugin => {
            return (
            (plugin.title && plugin.title.toLowerCase().includes(query)) ||
            (plugin.plugin && plugin.plugin.toLowerCase().includes(query)) ||
            (plugin.platform && plugin.platform.toLowerCase().includes(query)) ||
            (plugin.path && plugin.path.toLowerCase().includes(query))
            );
        });
        }

    },
    watch:{
    },
    methods: {
        fetchPlugin() {
            this.fetchPlugins()
        },
        async fetchPlugins() {
            this.loading = true; 
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/plugins/get-all`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("_t")}`,  
                    },
                });

                if (response.data.success) {
                    this.plugins = response.data.data; 
                    this.alertMessageToast = response.data.message || 'Plugins fetched successfully';
                    this.showToast("success"); 
                } else {
                    this.error = 'Failed to fetch plugins: ' + response.data.message;
                }
            } catch (error) {
                if (error.response) {
                    this.alertMessageToast = error.response.data.message || 'Error fetching plugins';
                    this.showToast("danger"); 
            
                } 
               
            } finally {
                this.loading = false;  
            }
        },

        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        removeThemeData() {
            this.editTheme = "";
        },
       

        fetchThemes() {
            this.$emit('fetchThemes');
        },
        editPluginData(theme) {
            this.editTheme = theme;
            this.$bvModal.show("edit-plugin-modal");
        },
        deletePlugin(id, name) {

            Swal.fire({
                title: "Are you sure?",
                text: `Do You want to delete plugin ${name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                
                    this.delete(id)
                }
            });
        },
        async delete(id) {
    this.loading = true;
    try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/plugin/delete-plugin/${id}`, {
            headers: {
                        Authorization: `Bearer ${localStorage.getItem("_t")}`,  
                    },
        });

        if (resp.data.success) {  
            this.alertMessageToast = resp.data.message;
            this.showToast("success"); 
            this.fetchPlugins();
        } else {
            this.errorMsg = resp.data.message || 'Failed to delete plugin';
            this.showToast("danger");
        }
    } catch (error) {
        if (error.response) {
            this.errorMsg = error.response.data.message || 'An error occurred';
        } else if (error.request) {
            this.errorMsg = 'No response received from server. Please check your network connection.';
        } else {
            this.errorMsg = 'Error in setting up the request: ' + error.message;
        }
        this.showToast("danger");
    } finally {
        // this.loading = false;  
    }
},
    },
    props: ["show", "themes", "manage", "token"],
};
</script>
<style>
#theme-table th:first-child {
    width: 100px;
}

#theme-table th:last-child {
    width: 100px;
}

#theme-table td {
    word-break: break-all;
}
</style>
